import React, { useEffect, useState } from "react";
import "../styles/loginpage.scss";
import "../styles/signin.scss";
import Photo from "../components/Photo";
import Form from "react-bootstrap/Form";
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { Auth, Amplify } from "aws-amplify";
import { navigate } from "gatsby";
import awsPoolConfiguration from "../aws-exports";
import { deleteCookie, deleteCognitoTokenFromLocalStorage, setCookie, getIdTokenFromCookie, getTokenDetailsByDecoding } from "../services/cookie-storage-service";
import { getLoginUserPoolInformation, getRegisteredUserByEmail, updateUserStatus, refreshUserSessionTime } from "../services/tenant-identity-service";
import { getTrialUserPoolInformation, updateTrialUserStatus } from "../services/trial-tenant-identity-service";
import "bootstrap/dist/css/bootstrap.min.css";
import SuccessAlert from "../components/common/SuccessAlert";
import ErrorAlert from "../components/common/ErrorAlert";
import { Helmet } from "react-helmet";
import { getMessages } from '../services/language-service';
import { CONFIRMED, FORCE_CHANGE_PASSWORD } from "../constant/user-login-status-enum";
import { useTranslation } from "react-i18next";
import { MESSAGE } from '../constant/message-enum';
import { Fragment } from 'react';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { CustomStorage } from '../custom-storage';

const initialFormValue = {
  email: "",
  password: "",
  newPassword: ""
}

function Login() {
  const { language } = React.useContext(I18nextContext);
  const GATSBY_ROLE_TENANT_ADMIN = process.env.GATSBY_ROLE_TENANT_ADMIN;
  const GATSBY_ROLE_SYSADMIN = process.env.GATSBY_ROLE_SYSADMIN;
  const GATSBY_CONFIGURATOR_URL = process.env.GATSBY_CONFIGURATOR_URL;
  const GATSBY_LOGIPULSE_APPLICATION_URL = process.env.GATSBY_LOGIPULSE_APPLICATION_URL;
  const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;
  const GATSBY_TRIAL_APPLICATION_URL = process.env.GATSBY_TRIAL_APPLICATION_URL;
  const GATSBY_ROLE_VENDOR = process.env.GATSBY_ROLE_VENDOR;
  const GATSBY_ROLE_CUSTOMER = process.env.GATSBY_ROLE_CUSTOMER;
  const GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL = process.env.GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL;

  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/login/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/login/`

  const canonicalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl();

  const { t } = useTranslation();

  const [alert_type, setAlertType] = useState("");
  const [alert_message, setAlertMessage] = useState("");
  const [formValues, setformValues] = useState(initialFormValue);
  const [isFirstTimeLogin, setFirstTimeLogin] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [checked, setCheckedValue] = useState(false);
  const [showEyeOption, setShowEyeOption] = useState(false);
  const [showNewPasswordEyeOption, setShowNewPasswordEyeOption] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  const [newPasswordType, setNewPasswordType] = useState('password');


  useEffect(() => {
    if (localStorage.getItem("gatsby-i18next-language")) {
      localStorage.removeItem("gatsby-i18next-language")
    }
  }, []);

  const handleCheck = (event) => {
    setCheckedValue(event.target.checked);
  }

  const handleChange = (event) => {
    const fieldName = event.target.name;
    const value = { ...formValues };
    value[event.target.name] = event.target.value;
    setformValues(value);

    if (fieldName === 'password') {
      setShowEyeOption(true);
      setShowPassword(false);
    } else if (fieldName === 'newPassword') {
      setShowNewPasswordEyeOption(true);
      setNewShowPassword(false);
    }
  }

  const clearAlertType = () => {
    setAlertType("");
    setAlertMessage("");
  }

  const navigateToForgotPasswordpage = event => {
    event.preventDefault();
    let currentLanguage = `${language}`;

    if (currentLanguage === "en") {
      navigate("/forgot-password");
    } else {
      navigate(`/${language}/forgot-password`);
    }
  }

  // const handleSubmit = async event => {
  //   try {
  //     event.preventDefault();
  //     clearAlertType();
  //     let currentLanguage = `${language}`;
  //     let email = formValues.email;
  //     let password = formValues.password;
  //     let userResult = [];
  //     setLoading(true);
  //     await deleteCognitoTokenFromLocalStorage();
  //     await deleteCookie();

  //     let registeredUserList = await getRegisteredUserByEmail(email);

  //     if (registeredUserList.length > 0) {
  //       let registeredUserResult = registeredUserList[0];

  //       if (registeredUserResult.is_Trial) {
  //         userResult = await getTrialUserPoolInformation(email);
  //       } else {
  //         userResult = await getLoginUserPoolInformation(email);
  //       }

  //       if (!checked) {
  //         setAlertType("ERROR");
  //         setAlertMessage(t("Please verify that you are not a robot"));
  //         setLoading(false);
  //       } else if (userResult.length > 0) {
  //         let userData = userResult[0];
  //         let userStatus = userData.userConfirmed;

  //         if (userStatus === CONFIRMED || userStatus === FORCE_CHANGE_PASSWORD) {
  //           let tenantResult = userData.Tenant;
  //           let userRole = userData.role.replaceAll(/\s/g, "");

  //           awsPoolConfiguration.aws_project_region = tenantResult.region;
  //           // awsPoolConfiguration.aws_cognito_identity_pool_id = tenantResult.identityPoolId;
  //           awsPoolConfiguration.aws_cognito_region = tenantResult.region;
  //           awsPoolConfiguration.aws_user_pools_id = tenantResult.userPoolId;
  //           awsPoolConfiguration.aws_user_pools_web_client_id = tenantResult.clientId;

  //           Amplify.configure(awsPoolConfiguration);
  //           Auth.configure(awsPoolConfiguration);

  //           // Amplify.configure({
  //           //   Auth: {
  //           //     region: tenantResult.region,
  //           //     userPoolId: tenantResult.userPoolId,
  //           //     userPoolWebClientId: tenantResult.clientId,
  //           //     storage: CustomStorage,
  //           //   },
  //           // })

  //           Auth.signIn(userData.email, password)
  //             .then(async (user) => {
  //               setLoading(false);
  //               // setCookie("currentLanguageGuid", currentLanguageGuid);
  //               setCookie("isSignIn", true);
  //               setCookie("isLoginIn", true);
  //               setCookie("currentLanguage", currentLanguage);

  //               if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
  //                 //Change password for tenant admin for first time login after create user in userpool during payment.
  //                 if (userRole === GATSBY_ROLE_TENANT_ADMIN && tenantResult.isPurchase === true && userData.hasOwnProperty("password") === true && typeof userData.password === 'object') {
  //                   setFirstTimeLogin(false);
  //                   changePasswordForFirstTimeLogin(user, formValues.password, userRole, tenantResult);
  //                 } else {
  //                   //Change password for first time login for user which is created from user module in application.
  //                   if (formValues.newPassword !== "") {
  //                     changePasswordForFirstTimeLogin(user, formValues.newPassword, userRole, tenantResult);
  //                   } else {
  //                     setFirstTimeLogin(true);
  //                   }
  //                 }
  //               } else {
  //                 if (tenantResult.hasOwnProperty("isTrialPool") === true && tenantResult.isTrialPool === true) {
  //                   if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
  //                     if (currentLanguage === "en") {
  //                       navigate("/location");
  //                     } else {
  //                       navigate(`/${language}/location`);
  //                     }
  //                   } else {
  //                     window.location.replace(GATSBY_TRIAL_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
  //                   }
  //                 } else if (tenantResult.isPurchase === false) {
  //                   window.location.replace(`${GATSBY_CONFIGURATOR_URL}/Summary`);
  //                 } else {
  //                   if (userRole === GATSBY_ROLE_CUSTOMER || userRole === GATSBY_ROLE_VENDOR) {
  //                     let validationMessage = await getMessages(MESSAGE.RESTRICT_USERPORTAL_USER_LOGIN, currentLanguage);
  //                     setAlertType("ERROR");
  //                     setAlertMessage(validationMessage);
  //                     setLoading(false);
  //                   } else if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
  //                     if (currentLanguage === "en") {
  //                       navigate("/signinsuccesspage")
  //                     } else {
  //                       navigate(`/${language}/signinsuccesspage`)
  //                     }
  //                   } else {
  //                     window.location.replace(GATSBY_LOGIPULSE_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
  //                   }
  //                 }
  //               }
  //             })
  //             .catch(error => {
  //               let errorObj = JSON.parse(JSON.stringify(error));
  //               let errMessage = errorObj.message;
  //               setAlertType("ERROR");
  //               setAlertMessage(errMessage);
  //               setLoading(false);
  //             });
  //         } else {
  //           let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
  //           setAlertType("ERROR");
  //           setAlertMessage(validationMessage);
  //           setLoading(false);
  //         }
  //       } else {
  //         let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
  //         setAlertType("ERROR");
  //         setAlertMessage(validationMessage);
  //         setLoading(false);
  //       }
  //     } else {
  //       let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
  //       setAlertType("ERROR");
  //       setAlertMessage(validationMessage);
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
  //     setAlertType("ERROR");
  //     setAlertMessage(errorMessage);
  //     setLoading(false);
  //   }

  //   //Remember Me function commented :- Reshma
  //   // let rmCheck = document.getElementById("rememberMe")
  //   // let emailInput = document.getElementById("email")

  //   // if (localStorage.checkbox && localStorage.checkbox !== "") {
  //   //   if (rmCheck) {
  //   //     rmCheck.setAttribute("checked", "checked")
  //   //     emailInput.value = localStorage.email
  //   //   }
  //   // } else {
  //   //   if (rmCheck) {
  //   //     rmCheck.removeAttribute("checked")
  //   //     emailInput.value = ""
  //   //   }
  //   // }
  // }

  // const changePasswordForFirstTimeLogin = (user, password, userRole, tenantResult) => {
  //   let currentLanguage = `${language}`;
  //   setLoading(true);

  //   Auth.completeNewPassword(user, password)
  //     .then(async (user) => {
  //       setLoading(false);
  //       if (tenantResult.hasOwnProperty("isTrialPool") === true && tenantResult.isTrialPool === true) {
  //         //Update user status to confirmed.
  //         await updateTrialUserStatus();

  //         if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
  //           if (currentLanguage === "en") {
  //             navigate("/location");
  //           } else {
  //             navigate(`/${language}/location`);
  //           }
  //         } else {
  //           window.location.replace(GATSBY_TRIAL_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
  //         }
  //       } else {
  //         //Update user status to confirmed.
  //         await updateUserStatus();

  //         if (userRole === GATSBY_ROLE_CUSTOMER || userRole === GATSBY_ROLE_VENDOR) {
  //           let validationMessage = await getMessages(MESSAGE.RESTRICT_USERPORTAL_USER_LOGIN, currentLanguage);
  //           setAlertType("ERROR");
  //           setAlertMessage(validationMessage);
  //           setLoading(false);
  //         } else if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
  //           if (currentLanguage === "en") {
  //             navigate("/signinsuccesspage")
  //           } else {
  //             navigate(`/${language}/signinsuccesspage`)
  //           }
  //         } else {
  //           setCookie("currentLanguage", currentLanguage)
  //           window.location.replace(GATSBY_LOGIPULSE_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
  //         }
  //       }
  //     })
  //     .catch(async (err) => {
  //       let validationMessage = await getMessages(MESSAGE.PASSWORD_POLICY_MESSAGE, currentLanguage);
  //       setAlertType("ERROR");
  //       setAlertMessage(validationMessage);
  //       setLoading(false);
  //     })
  // }

  const changePassword = (cognitoUser, newPassword, userRole, tenantResult, userAttr) => {
    let currentLanguage = `${language}`;
    setLoading(true);
    delete userAttr.email_verified;
    delete userAttr.email;

    cognitoUser.completeNewPasswordChallenge(newPassword, userAttr, {
      onSuccess: async function (result) {
        setLoading(false);

        if (tenantResult.hasOwnProperty("isTrialPool") === true && tenantResult.isTrialPool === true) {
          //Update user status to confirmed.
          await updateTrialUserStatus();

          if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
            if (currentLanguage === "en") {
              navigate("/location");
            } else {
              navigate(`/${language}/location`);
            }
          } else {
            window.location.replace(GATSBY_TRIAL_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
          }
        } else {
          //Update user status to confirmed.
          await updateUserStatus();

          if (userRole === GATSBY_ROLE_CUSTOMER || userRole === GATSBY_ROLE_VENDOR) {
            let validationMessage = await getMessages(MESSAGE.RESTRICT_USERPORTAL_USER_LOGIN, currentLanguage);
            setAlertType("ERROR");
            setAlertMessage(validationMessage);
            setLoading(false);
          } else if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
            if (currentLanguage === "en") {
              navigate("/signinsuccesspage")
            } else {
              navigate(`/${language}/signinsuccesspage`)
            }
          } else {
            setCookie("currentLanguage", currentLanguage)
            window.location.replace(GATSBY_LOGIPULSE_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
          }
        }
      },
      onFailure: async function (err) {
        //let errMessage = '';
        //let errorObj = JSON.parse(JSON.stringify(err));

        // if (errorObj.name === "NotAuthorizedException") {
        //   errMessage = await getMessages(MESSAGE.INCORRECT_USERNAME_OR_PASSWORD, currentLanguage);
        //   setAlertType("ERROR");
        //   setAlertMessage(errMessage);
        // } else {
        //   setAlertMessage(err);
        // }

        let validationMessage = await getMessages(MESSAGE.PASSWORD_POLICY_MESSAGE, currentLanguage);
        setAlertType("ERROR");
        setAlertMessage(validationMessage);
        setLoading(false);
      },
    });
  };

  const showPasswordValue = (event) => {
    event.preventDefault();
    setShowPassword(false);
    setPasswordType('password');
  }

  const hidePasswordValue = (event) => {
    event.preventDefault();
    setShowPassword(true);
    setPasswordType('text');
  }

  const showNewPasswordValue = (event) => {
    event.preventDefault();
    setNewShowPassword(false);
    setNewPasswordType('password');
  }

  const hideNewPasswordValue = (event) => {
    event.preventDefault();
    setNewShowPassword(true);
    setNewPasswordType('text');
  }

  const loginSubmit = async event => {
    try {
      event.preventDefault();
      clearAlertType();
      let currentLanguage = `${language}`;
      let email = formValues.email;
      let userResult = [];
      setLoading(true);
      await deleteCognitoTokenFromLocalStorage();
      await deleteCookie();

      let registeredUserList = await getRegisteredUserByEmail(email);

      if (registeredUserList.length > 0) {
        let registeredUserResult = registeredUserList[0];

        if (registeredUserResult.is_Trial) {
          userResult = await getTrialUserPoolInformation(email);
        } else {
          userResult = await getLoginUserPoolInformation(email);
        }

        if (!checked) {
          setAlertType("ERROR");
          setAlertMessage(t("Please verify that you are not a robot"));
          setLoading(false);
        } else if (userResult.length > 0) {
          let userData = userResult[0];
          let userStatus = userData.userConfirmed;

          if (userStatus === CONFIRMED || userStatus === FORCE_CHANGE_PASSWORD) {
            await authenticateUserCredential(userData);
          } else {
            let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
            setAlertType("ERROR");
            setAlertMessage(validationMessage);
            setLoading(false);
          }
        } else {
          let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
          setAlertType("ERROR");
          setAlertMessage(validationMessage);
          setLoading(false);
        }
      } else {
        let validationMessage = await getMessages(MESSAGE.USER_DOES_NOT_EXIST, currentLanguage);
        setAlertType("ERROR");
        setAlertMessage(validationMessage);
        setLoading(false);
      }
    } catch (error) {
      let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
      setAlertType("ERROR");
      setAlertMessage(errorMessage);
      setLoading(false);
    }
  }

  const authenticateUserCredential = async (userObj) => {
    try {
      const deviceType = /mobile|android|iphone|ipad/.test(navigator.userAgent.toLowerCase()) ? "Mobile" : "Web";

      let { email, password } = formValues;
      let { Tenant } = userObj;
      let { userPoolId, clientId } = Tenant;

      let authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
        ClientMetadata: {
          'deviceType': deviceType
        }
      });

      let poolData = {
        UserPoolId: userPoolId,
        ClientId: clientId
      };

      let userPool = new CognitoUserPool(poolData);
      let userData = {
        Username: email,
        Pool: userPool,
        Storage: CustomStorage
      };

      var cognitoUser = new CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (session) => {
          setLoading(false);
          await setCookieValue();
          await redirectPage(Tenant, userObj);
          await configureUserPool(Tenant);
        },
        onFailure: async (err) => {
          setLoading(false);
          setAlertType("ERROR");
          setAlertMessage(err.message);
        },
        newPasswordRequired: async (userAttr) => {
          setLoading(false);
          await setNewPasswordForFirstTimeLogin(userObj, Tenant, cognitoUser, userAttr);
        }
      });
    } catch (error) {
      let errorMessage = error.hasOwnProperty('errorMessage') ? error.errorMessage : error.message;
      setAlertType("ERROR");
      setAlertMessage(errorMessage);
      setLoading(false);
    }
  }

  const setNewPasswordForFirstTimeLogin = async (userResult, tenantResult, cognitoUser, userAttr) => {
    let userRole = userResult.role;
    //Change password for tenant admin for first time login after create user in userpool during payment.
    if (userRole === GATSBY_ROLE_TENANT_ADMIN && tenantResult.isPurchase === true && userResult.hasOwnProperty("password") === true && typeof userResult.password === 'object') {
      setFirstTimeLogin(false);
      changePassword(cognitoUser, formValues.password, userRole, tenantResult, userAttr);
    } else {
      //Change password for first time login for user which is created from user module in application.
      if (formValues.newPassword !== "") {
        changePassword(cognitoUser, formValues.newPassword, userRole, tenantResult, userAttr);
      } else {
        setFirstTimeLogin(true);
      }
    }

  }

  const configureUserPool = async (tenantResult) => {
    awsPoolConfiguration.aws_project_region = tenantResult.region;
    awsPoolConfiguration.aws_cognito_identity_pool_id = tenantResult.identityPoolId;
    awsPoolConfiguration.aws_cognito_region = tenantResult.region;
    awsPoolConfiguration.aws_user_pools_id = tenantResult.userPoolId;
    awsPoolConfiguration.aws_user_pools_web_client_id = tenantResult.clientId;

    Amplify.configure(awsPoolConfiguration);
    Auth.configure(awsPoolConfiguration);

    await Auth.currentSession()
      .then((res) => {
      })
      .catch((err) => {
      });
  }

  const redirectPage = async (tenantResult, userResult) => {
    try {
      let currentLanguage = `${language}`;
      let userRole = userResult.role.replaceAll(/\s/g, "");

      if (tenantResult.hasOwnProperty("isTrialPool") === true && tenantResult.isTrialPool === true) {
        if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
          if (currentLanguage === "en") {
            navigate("/location");
          } else {
            navigate(`/${language}/location`);
          }
        } else {
          await handleSessionInfo();
          window.location.replace(GATSBY_TRIAL_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
        }
      } else if (tenantResult.isPurchase === false) {
        window.location.replace(`${GATSBY_CONFIGURATOR_URL}/Summary`);
      } else {
        if (userRole === GATSBY_ROLE_TENANT_ADMIN || userRole === GATSBY_ROLE_SYSADMIN) {
          if (currentLanguage === "en") {
            navigate("/signinsuccesspage")
          } else {
            navigate(`/${language}/signinsuccesspage`)
          }
        } else {
          await handleSessionInfo();
          window.location.replace(GATSBY_LOGIPULSE_APPLICATION_URL + GATSBY_LOGIPULSE_APPLICATION_LANDING_REDIRECTION_URL);
        }
      }
    } catch (error) {
    }
  }

  const handleSessionInfo = async () => {
    let idTokenFromCookie = await getIdTokenFromCookie();
    if (idTokenFromCookie !== "") {
      let tokenDetails = getTokenDetailsByDecoding(idTokenFromCookie);
      let locationGuid = tokenDetails.locationGuid;
      let locationCurrencyGuid = tokenDetails.currencyGuid;
      let sessionGuid = tokenDetails.sessionGuid;

      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        'custom:locationGuid': locationGuid,
        'custom:currencyGuid': locationCurrencyGuid,
        'custom:sessionGuid': sessionGuid
      });

      const cognitoUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
      const currentSession = await Auth.currentSession();
      cognitoUser.refreshSession(currentSession.refreshToken);
      await refreshUserSessionTime(sessionGuid);
    }
  }

  const setCookieValue = async () => {
    let currentLanguage = `${language}`;
    setCookie("isSignIn", true);
    setCookie("isLoginIn", true);
    setCookie("currentLanguage", currentLanguage);
  }

  return (
    <div>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `
      
<!-- Google Tag Manager -->

      <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
      
          `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
      
<!-- Google Tag Manager -->

      <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
          `,
          }}
        />
        <meta charSet="utf-8" />
        <title>Logipulse Freight Forwarding Solutions</title>
        <Link rel="canonical" href={canonicalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t("Sign in to Logipulse software and keep moving forward for a seamless logistics experience. Enjoy hassle-free management of your freight.")}
        />
        <meta
          name="Keywords"
          content={t("Logipulse login, Logipulse Sign in")}
          data-react-helmet="true"
        />
      </Helmet>
      <div
        className="display_flex flex_wrap height_100p"
        lang={language}
      >
        <div
          className="login_left_side blue_color_fifth_bg height_100p"
          lang={language}
        >
          <div
            className="width_100 height_100p padding_24 position_relative display_flex flex_align_center justify_center"
            lang={language}
          >
            <div className="logo">
              <div className="display_flex justify_center">
                <Link to="/" language={language}>
                  <Photo src="Logo Icon.svg" alt="" className="powered_logo" />
                </Link>
              </div>
              <div className="displa_flex justify_center margin_top_36">
                <Photo src="Tagline.svg" alt="" className="powered_logo" />
              </div>
            </div>
            <div className="powered_by font_12 muller_light">
              <Trans>Powered</Trans>
              <Trans>by</Trans>
              <Photo
                src="Stellosysyw-Logo.svg"
                alt=""
                className="powered_logo width_48"
              />
              <span className="login_copyright">
                <Trans>Copyright 2021 Stellosys All rights Reserved.</Trans>
              </span>
            </div>
          </div>
        </div>

        <div
          className="login_right_side fifth_blue_bg height_100p display_flex flex_align_center login_main position_relative"
          language={language}
        >
          <div
            className="form_section"
            lang={language}
          >
            <div className="login_alert_msg">
              {alert_type === "SUCCESS" ? (
                <SuccessAlert message={alert_message} />
              ) : null}

              {alert_type === "ERROR" ? (
                <ErrorAlert message={alert_message} />
              ) : null}
            </div>

            <div className="logo_section">
              <Link to="/" language={language}>
                {" "}
                <Photo
                  src="LogiPulse-Logo-H48.svg"
                  className="full_logo_width"
                  alt="LOGIPULSE"
                />
              </Link>
            </div>
            <div className="login_header hard_blue_color muller_bold margin_top_48 margin_bottom_24 font_24">
              <Trans>Login</Trans>
            </div>
            <div className="field_section width_360">
              <Form onSubmit={loginSubmit} autoComplete="off">
                <Form.Group controlId="formBasicEmail">
                  <div className="input_custom_form_style">
                    <Form.Label>
                      <Trans>Email</Trans>
                    </Form.Label>
                  </div>
                  <Form.Control
                    type="email"
                    name="email"
                    required
                    onChange={handleChange}
                    value={formValues.email}
                    className="input_style"
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <div className="input_custom_form_style">
                    <Form.Label>
                      <Trans>Password</Trans>
                    </Form.Label>
                  </div>
                  <div className="position_relative">
                    <Form.Control
                      type={passwordType}
                      name="password"
                      value={formValues.password}
                      required
                      onChange={handleChange}
                      className="input_style"
                    />

                    {
                      showEyeOption ? (<div className="eyeposition">
                        {
                          showPassword ? (<div className="eyeopen" onClick={showPasswordValue}>
                            {/* <FontAwesomeIcon icon={faEye} /> */}
                            <Photo
                              src="eye_visibility_on.png"
                              className="width_20"
                              alt="eye_visibility_on"
                            />
                          </div>)
                            :
                            (<div className="eyeclose" onClick={hidePasswordValue}>
                              {/* <FontAwesomeIcon icon={faEyeSlash} /> */}

                              <Photo
                                src="eye_visibility_off.png"
                                className="width_20"
                                alt="eye_visibility_off"
                              />
                            </div>)
                        }
                      </div>) : null
                    }

                  </div>
                </Form.Group>

                {isFirstTimeLogin ? (
                  <Fragment>
                    <Form.Group controlId="formBasicResetPassword">
                      <div className="input_custom_form_style">
                        <Form.Label>
                          <Trans>New Password</Trans>
                        </Form.Label>
                      </div>
                      <div className="position_relative">
                        <Form.Control
                          type={newPasswordType}
                          name="newPassword"
                          value={formValues.newPassword}
                          required="required"
                          onChange={handleChange}
                          className="input_style"
                        />
                        {
                          showNewPasswordEyeOption ? (<div className="eyeposition">
                            {
                              showNewPassword ? (<div className="eyeopen" onClick={showNewPasswordValue}>
                                {/* <FontAwesomeIcon icon={faEye} /> */}
                                <Photo
                                  src="eye_visibility_on.png"
                                  className="width_20"
                                  alt="eye_visibility_on"
                                />
                              </div>)
                                :
                                (<div className="eyeclose" onClick={hideNewPasswordValue}>
                                  {/* <FontAwesomeIcon icon={faEyeSlash} /> */}
                                  <Photo
                                    src="eye_visibility_off.png"
                                    className="width_20"
                                    alt="eye_visibility_on"
                                  />
                                </div>)
                            }
                          </div>) : null
                        }
                      </div>
                    </Form.Group>

                    <div className="password_policy">Use 8 or more characters with a mix of uppercase and lowercase letters, numbers & symbols</div>
                  </Fragment>
                ) : null}

                <Form.Group
                  className="sign-bottom-section"
                  controlId="formBasicCheckbox"
                >
                  <div className="chkbox_btn_align">
                    <div className="chkbox">
                      <Form.Check
                        className="login_checkbox"
                        onChange={handleCheck}
                        defaultChecked={checked}
                        type="checkbox"
                        label={t("I'm not a robot")}
                        language={language}
                      />
                    </div>

                    <div className="signin_btn">
                      <button
                        className="login_submit_button font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative"
                        type="submit"
                        disabled={isLoading}
                        language={language}
                      >
                        <Trans>LOGIN</Trans>
                        {isLoading && (
                          <Photo
                            src="loader_64.gif"
                            className="spinner"
                            alt=""
                          />
                        )}
                      </button>
                    </div>
                  </div>

                </Form.Group>

                <div className="txt_blue_color font_12 pointer" onClick={navigateToForgotPasswordpage}>
                  <Trans>Forgot Password?</Trans>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
